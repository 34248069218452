import React from "react";
import ReactDOM from "react-dom/client";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Success from "./components/Success";
import Disclaimer from "./components/TnC/Disclaimer"
import Privacy from "./components/TnC/Privacy"

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },

  {
    path: "/success",
    element: <Success />,
  },  
  {
    path: "/disclaimer",
    element: <Disclaimer />,
  }, 
  {
    path: "/privacy",
    element: <Privacy />,
  }, 
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
