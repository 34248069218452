import React, { useState } from "react";
import { Navigate } from "react-router-dom"; // Import Redirect
import emailjs from "emailjs-com";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardContent, Container } from "@material-ui/core";
import Box from "@mui/material/Box";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import { Gmap } from "../../Landing";

const ContactUs = () => {
const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [subject, setSubject] = useState("");
const [message, setMessage] = useState("");
const [nameError, setNameError] = useState(false);
const [emailError, setEmailError] = useState(false);
const [subjectError, setSubjectError] = useState(false);
const [messageError, setMessageError] = useState(false);
const [isSubmitted, setIsSubmitted] = useState(false); // State to track submission

const toastifySuccess = () => {
    toast("Your message has been sent successfully!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    className: "submit-feedback success",
    toastId: "notifyToast",
    });
};

const handleSubmit = (e) => {
    e.preventDefault();
    if (name && email && message) {
    emailjs
        .sendForm(
        "service_0ywanb8",
        "template_4u67s9r",
        e.target,
        "D8p-p81H1BZV_b0GS"
        )
        .then(
        (result) => {
            console.log(result.text);
            toastifySuccess(); // Call the success toast function here
            setIsSubmitted(true); // Set submission state to true
        },
        (error) => {
            console.log(error.text);
        }
        );
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
    setNameError(false);
    setEmailError(false);
    setSubjectError(false);
    setMessageError(false);
    } else {
    if (!name) {
        setNameError(true);
    }
    if (!email) {
        setEmailError(true);
    }
    if (!subject) {
        setSubjectError(true);
    }
    if (!message) {
        setMessageError(true);
    }
    }
};

if (isSubmitted) {
    // Redirect to a different URL upon successful form submission
    return <Navigate to="/success" />;
}

return (

    <>
    <Container maxWidth="lg">
        <Gmap>
        <div className="map">
            <div
            style={{
                height: "400px",
                width: "100%",
                position: "relative",
            }}
            >
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.671959830491!2d103.76191507544766!3d1.3735107986135178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da17c4e8715bbb%3A0xa77c21d326f4afd7!2sThe%20Myst!5e0!3m2!1sen!2ssg!4v1723286774021!5m2!1sen!2ssg"
                style={{ border: 0, width: "100%", height: "100%" }}
                title="location"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>

            </div>
        </div>

        <div className="info-cont">
            <div>
            <Card sx={{ maxWidth: 345, }}>
                <CardContent>
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{ fontWeight: 700 }}
                >
                    Contact Information
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    Fill up the form and our team will get back to you within 24
                    hours.
                </Typography>
                <br />
                <br />
                <a href="https://wa.me/6598477000">
                    <Box sx={{ alignItems: "center" }}>
                    <RiWhatsappFill
                        size={24}
                        color={"#25D366"}
                        sx={{ cursor: "pointer" }}
                    />
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{ paddingLeft: "10px" }}
                    >
                        (+65) 9847 7000
                    </Typography>
                    </Box>
                </a>

                <br />
                <br />
                <a href="mailto:themystsalesteam@gmail.com">
                    <Box sx={{ alignItems: "center" }}>
                    <MdEmail size={24} color={"#006DB5"} />
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{ paddingLeft: "10px" }}
                    >
                        themystsalesteam@gmail.com
                    </Typography>
                    </Box>
                </a>
                <br />
                <br />
                <Box sx={{ alignItems: "center" }}>
                    <MdLocationOn size={24} color={"#000000"} />
                    <Typography
                    variant="body1"
                    component="p"
                    sx={{ paddingLeft: "10px" }}
                    >
                    800/802 Upper Bukit Timah Road
                    </Typography>
                </Box>
                <br />
                <br />
                </CardContent>
            </Card>
            </div>

            <div className="location-info">
            <Box
                sx={{
                bgcolor: "#f2f2f2",
                p: "11px 15px",
                borderRadius: 2,
                display: "grid",
                placeCotnent: "center",
                mx: "auto",
                my: "auto"
                }}
            >
                <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <TextField
                        required
                        label="Name"
                        value={name}
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        error={nameError}
                    />
                    </Grid>

                    <Grid item xs={12}>
                    <TextField
                        required
                        label="Email"
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        error={emailError}
                    />
                    </Grid>

                    <Grid item xs={12}>
                    <TextField
                        required
                        label="Phone Number"
                        value={subject}
                        name="subject"
                        onChange={(e) => setSubject(e.target.value)}
                        fullWidth
                        error={subjectError}
                    />
                    </Grid>

                    <Grid item xs={12}>
                    <TextField
                        required
                        label="Message"
                        value={message}
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                        fullWidth
                        multiline
                        rows={5}
                        error={messageError}
                    />
                    </Grid>

                    <Grid item xs={12}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{
                        background: "#00A36C",
                        fontWeight: "bold",
                        marginTop: "16px",
                        color: "#FFFFFF",
                        }}
                    >
                        Send Message
                    </Button>
                    </Grid>
                </Grid>
                </form>
            </Box>
            </div>
        </div>
        </Gmap>
    </Container>

    </>

);
};

export default ContactUs;
