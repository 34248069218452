import React, { useEffect, useRef } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// import { Pagination, Navigation } from "swiper/modules";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import {
Card,
CardContent,
CardMedia,
CardActions,
} from "@material-ui/core";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Link as Rlink } from "react-router-dom";

import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import ConstructionIcon from "@mui/icons-material/Construction";
import StoreIcon from "@mui/icons-material/Store";
import WavesIcon from "@mui/icons-material/Waves";
import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";

import "./Landing.css";
import { Typography } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { SiAdguard } from "react-icons/si";
import { SiLeaderprice } from "react-icons/si";
import { FaAward } from "react-icons/fa";

import { SiHouzz } from "react-icons/si";

import { useLocation } from "react-router-dom";

export const Gmap = styled.div`
margin: 100px 0;
display: grid;
grid-template-columns: repeat(1, 1fr);
gap: 20px;

.map {
}

.info-cont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    text-align: left !important;
}

.location-info {
    text-transform: capitalize;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    text-align: left;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;

    color: #606060;

    svg {
    font-size: 2rem;
    }

    .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 20px;
    }
}

.abs-info {
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -10%);
    width: 76%;

    padding: 20px 40px;
    border-radius: 0.8rem;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;

    div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;

    color: #606060;
    }
}

@media screen and (max-width: 899px) {
    .info-cont {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    }
}
`;

export default function Landing() {
const location = useLocation();
const currentHash = location.hash;

const homeRef = useRef(null);


useEffect(() => {
    setTimeout(() => {
    if (currentHash) {
        console.log(currentHash);
        let dom = document.querySelector(`[href="${currentHash}"]`);
        dom.click();
    }
    }, 100);
}, [currentHash]);

function handleNavClick(href) {
    console.log(href);
    setTimeout(() => {
    if (href) {
        console.log(href.split("#")[1]);
        let dom = document.querySelector(`[href="#${href.split("#")[1]}"]`);
        dom.click();
    }
    }, 100);
}

return (
    <Box ref={homeRef} sx={{ overflowX: "hidden" }} className="main-container">
    <NavBar handleNavClick={handleNavClick} />

    {/* <Box className="landing-content"> */}
    <Grid
        container
        sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url("./themysthero2.jpg")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        }}
    >
        {/* HERO SECTION */}


        <Grid item xs={12} sm={12} md={8}>
        <Box
            ml={{ xs: 0, md: 18 }}
            mb={6}
            sx={{ maxWidth: { xs: "100%", sm: "100%" },
            backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
            borderRadius: "8px", // Adjust the radius for rounded corners
            padding: 3, // Add padding inside the rectangle
            boxShadow: 1, // Optional: Adds a subtle shadow for better separation
        }}
        >
            <Typography
            variant="h2"
            sx={{
                textAlign: {
                    xs: "center",
                    md: "left",
                },
                color: "white", // Set text color to white
            }}
            >
            The Myst
            <br />
            <Typography
                variant="h4"
                fontStyle="italic"
                sx={{ color: "white" }} // Ensure nested Typography is also white
            >
                By City Developments Limited
            </Typography>
            </Typography>
            <Box
            mt={4}
            mb={2}
            sx={{
                textAlign: {
                xs: "center",
                md: "left",
                },
            }}
            >
            <Button
                variant="contained"
                component="a"
                href="#contact"
                style={{
                fontWeight: "bold",
                backgroundColor: "#00A36C", // change to your desired color
                color: "#FFFFFF", // change to contrast with the background color
                }}
            >
                Contact Sales
            </Button>
            </Box>
        </Box>
        </Grid>
    </Grid>
    {/* </Box> */}

    {/* ABOUT US SECTION */}
    <div id="about">
        <Box>
        <Grid
        container
        sx={{
            backgroundColor: "#00A36C",
            padding: "10px 0",
        }}
        >
        <Grid item xs={12} md={4}>
            <FaAward size={42} color={"#FFFFFF"} />
            <Typography variant="h5" color="#FFFFFF" fontWeight="700" my={1}>
            DEVELOPER
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
            City Development Limited (CDL)
            </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
            <SiAdguard size={42} color={"#FFFFFF"} />
            <Typography variant="h5" color="#FFFFFF" fontWeight="700" my={1}>
            TOP DATE
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
            2027
            </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
            <SiHouzz size={42} color={"#FFFFFF"} />
            <Typography variant="h5" color="#FFFFFF" fontWeight="700" my={1}>
            TOTAL UNITS
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
            408
            </Typography>
        </Grid>
        </Grid>

        <img
            src="../themystaboutus.png" // Replace with your image path
            alt="Property Facts"
            style={{ width: '100%', maxHeight: '800px', objectFit: 'cover', marginTop: '6px' }}
        />
        </Box>
    </div>

    {/* PRODUCTS SECTION */}
    <Box id="floorplans" py={0} px={0} sx={{ width: '100%' }}>
            <Box textAlign="center">
                <Typography
                    variant="h4"
                    sx={{ color: "#00704a", fontWeight: 700 }}
                >
                    The Myst
                </Typography>
                <img
                    src="../themystmain.png" // Replace with your image path
                    alt="The Myst"
                    style={{ width: '100%', maxHeight: '400px', objectFit: 'cover', marginTop: '6px' }}
                />
                <Typography
                    variant="h6"
                    sx={{ mt: 2, maxWidth: "100%", mx: "auto", padding: '0 16px', textAlign: 'justify', }}
                >
                    The Myst is located along Upper Bukit Timah Road in district 23, The Myst has a beautiful landscape where by 75% is landscaping and only 25% is building. The Myst is a 99 years leasehold brand new condominium. The Myst is 5 mins walk to Cashew MRT Station. The Myst will be located in the low density living with lush greens enhancing the quality of living. The Myst is nestled in lush neighbourhood surrounded by several Nature Parks and Reservoirs. The Myst is less than 10 mins walk to Hillion Mall and Bukit Panjang Plaza and also to Bukit Panjang Integrated Transport Hub and Bus Interchange. The Myst is another prestigious development by the renowned City Developments Limited. The Myst is a short drive to Jurong Innovation District and Jurong Lake District. The Myst is insipired by the misty mornings in Singapore's largest rainforest, the Bukit Timah Nature Reserve. The inspiration extends into the architectural design where a misty ambience permeates the layered forest landscaping, allowing residents to immerse in a sense of tranquility the moment they come home. The Myst has a 24-km green passage of biodiversity and heritage along the Rail Corridor or ride or hike to the Truss Bridge - a popular photo spot that was once part of the Bukit Timah Railway, as such live & breathe with nature at your doorstep.
                </Typography>
            </Box>
            <Box textAlign="center">
                <Typography
                    variant="h4"
                    sx={{ color: "#00704a", fontWeight: 700 }}
                >
                    The Myst Floor Plan
                </Typography>
                <img
                    src="../themysthero.jpg" // Replace with your image path
                    alt="The Myst Floor Plan"
                    style={{ width: '100%', maxHeight: '400px', objectFit: 'cover', marginTop: '6px' }}
                />
                <Typography
                    variant="h6"
                    sx={{ mt: 2, maxWidth: "100%", mx: "auto", padding: '0 16px', textAlign: 'justify', }}
                >
                    The Myst Floor Plan, which rises 24 stories above Singapore's District 23, will undoubtedly rank among the most remarkable structures to grace the skyline of Singapore, with spectacular views of the city. The Myst Floor Plan offers 408 units, from the magnificent 1-bedroom units to 5-bedroom. The Myst Floor Plan has been carefully planned and built to provide great value for the most affluent and powerful people of our time. The Myst Floor Plan has a spacious and efficient layout well liked by many buyers both local and foreign buyers.
                </Typography>
            </Box>

            <Box textAlign="center">
                <Typography
                    variant="h4"
                    sx={{ color: "#00704a", fontWeight: 700, marginTop: '16px' }}
                >
                    The Myst Location
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ mt: 2, maxWidth: "100%", mx: "auto", padding: '0 16px', textAlign: 'justify', }}
                >
                    The Myst Location offers owners a valued place to live, work, and entertain thanks to its extensive amenities and location being surrounded by many eateries, malls and various lifestyle offerings. The Myst Location of showflat is not on site, thus viewing is only by The Myst showflat and The Myst sales gallery appointment, please kindly contact +65 6100 7700.
                </Typography>
                <img
                    src="../themystlocation.jpg" // Replace with your image path
                    alt="The Myst Location"
                    style={{ width: '100%', maxHeight: '400px', objectFit: 'cover', marginTop: '6px' }}
                />
                
            </Box>
    </Box>


    {/* FLOOR PLAN SECTION */}
    <Box
    sx={{
        padding: "1rem",
        paddingTop: 10,
        paddingBottom: 10,
    }}
    >

    <Typography
            variant="h4"
            sx={{ color: "#00704a", fontWeight: 700, marginBottom: '10px' }}
        >
            Floor Plans
    </Typography>
    {/* Grid Container */}
    <Grid container spacing={1}>
        <Grid item xs={12} md={3} lg={3}>
        <img
            src="../floorplan1.png" // Replace with your image path
            alt="1BR + STUDY"
            style={{ width: '60%', height: 'auto', objectFit: 'cover' }}
        />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
        <img
            src="../floorplan2.png" // Replace with your image path
            alt="2BR"
            style={{ width: '60%', height: 'auto', objectFit: 'cover' }}
        />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
        <img
            src="../floorplan3.png" // Replace with your image path
            alt="3BR"
            style={{ width: '60%', height: 'auto', objectFit: 'cover' }}
        />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
        <img
            src="../floorplan4.png" // Replace with your image path
            alt="2BR + STUDY"
            style={{ width: '60%', height: 'auto', objectFit: 'cover' }}
        />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
        <img
            src="../floorplan5.png" // Replace with your image path
            alt="3BR PREMIUM"
            style={{ width: '60%', height: 'auto', objectFit: 'cover' }}
        />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
        <img
            src="../floorplan6.png" // Replace with your image path
            alt="3BR PREMIUM + STUDY"
            style={{ width: '60%', height: 'auto', objectFit: 'cover' }}
        />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
        <img
            src="../floorplan7.png" // Replace with your image path
            alt="4BR"
            style={{ width: '60%', height: 'auto', objectFit: 'cover' }}
        />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
        <img
            src="../floorplan8.png" // Replace with your image path
            alt="5BR"
            style={{ width: '60%', height: 'auto', objectFit: 'cover' }}
        />
        </Grid>
    </Grid>
    </Box>

    {/* CONTACT SECTION */}
    

        <Typography
                    variant="h4"
                    sx={{ color: "#00704a", fontWeight: 700 }}
                >
                    Nearby Places
        </Typography>

        <img
            src="../nearbyplaces.png" // Replace with your image path
            alt="Nearby Places"
            style={{ width: '70%', maxHeight: '800px', objectFit: 'cover', marginTop: '6px' }}
        />

        <img
            src="../themystlocation2.jpg" // Replace with your image path
            alt="Site Map"
            style={{ width: '70%', maxHeight: '800px', objectFit: 'cover', marginTop: '6px' }}
        />
        <img
            src="../themystlocation3.png" // Replace with your image path
            alt="Site Map"
            style={{ width: '70%', maxHeight: '800px', objectFit: 'cover', marginTop: '6px' }}
        />

    <div id="contact">
        <Typography
        sx={{ paddingLeft: "20px", color: "#00704a" }}
        fontSize={30}
        fontWeight={700}
        id="contact"
        >
        LOCATION MAP
        </Typography>

        <Box>
        <Grid
            container
            sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}
        >
            <ContactUs />
            <ToastContainer />
        </Grid>
        </Box>
    </div>

    <Box>
        <Footer />
    </Box>
    </Box>
);
}
