import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Custom styles
const useStyles = makeStyles((theme) => ({
container: {
    textAlign: 'center',
    marginTop: theme.spacing(6),
},
title: {
    marginBottom: theme.spacing(4),
},
policyText: {
    textAlign: 'left',
    marginBottom: theme.spacing(4),
},
backButton: {
    backgroundColor: '#00A36C',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    color: '#FFFFFF',
},
}));

const Privacy = () => {
const classes = useStyles();

return (
    <Container maxWidth="sm" className={classes.container}>
    <Typography variant="h4" className={classes.title}>
        Privacy Policy
    </Typography>
    <Typography variant="body1" className={classes.policyText}>
        This External Privacy Policy (the “Policy”) applies to the collection, use, and disclosure of Personal Data (hereinafter defined) of its clients/customers by Huttons Asia Pte Ltd (“Huttons” or “the Organisation”).
        
        1. GENERAL
        1.1 This Policy provides information on the obligations and policies of Huttons in respect of an individual customer’s Personal Data. Huttons is committed to using reasonable efforts in applying, where practicable, those principles and the processes set out in this Policy to its operations. Please take a moment to read this Policy so that you know and understand the purposes for which we collect, use, and disclose your Personal Data.
        1.2 Huttons’ management, staff, and associates/agents shall endeavor to respect the confidentiality of and keep safe all Personal Data collected, stored, disclosed, and used for, or on behalf of, Huttons. Huttons strives to ensure all collection, storage, disclosure, and usage of Personal Data by the Organisation and by anyone acting on its behalf shall be done in an appropriate manner and in accordance with the Personal Data Protection Act and this Policy.
        1.3 By interacting with us, submitting information to us, or engaging in real estate services offered by us, you agree and consent to Huttons as well as to its respective representatives, associates, and agents (collectively referred to herein as “Huttons”, “us”, “we” or “our”) collecting, using, disclosing and sharing amongst themselves your Personal Data, and disclosing such Personal Data to Huttons’ authorized service providers and relevant third parties in the manner set forth in this Policy.
        1.4 This Policy supplements but does not supersede nor replace any other consents you may have previously provided to us in respect of your Personal Data, and your consents herein are additional to any rights which we may have at law to collect, use or disclose your Personal Data.
        1.5 For the purposes of this Policy, in line with the provisions under the Singapore Personal Data Protection Act 2012 (No. 26 of 2012) (the “Act”), “Personal Data” shall mean data, whether true or not, about an individual who can be identified from that data; or from that data and other information to which an organization has or is likely to have access. Such Personal Data shall also refer to that which is already in the possession of Huttons or that which shall be collected by Huttons in the future.

        2. CONTACTING THE DATA PROTECTION OFFICER
        2.1 Where you:
        
        legitimately request access to Personal Data relating to you which is in the possession and control of Huttons;
        have any questions or feedback relating to your Personal Data or this Policy; or
        wish to correct Personal Data relating to you which is in the possession and control of Huttons, you may contact our Data Protection Officer (“DPO”) in accordance with our standard procedures as stated in this Policy. We shall accede to your request within a reasonable time and manner.
        2.2 In accordance with the Personal Data Protection Act, Huttons has established a process for receiving and responding to any query or complaint that may arise with respect to the application of this Act. To ensure that Huttons receives your complaints and inquiries, please send the same via email to the DPO at the following email address: dpo@huttonsgroup.com.
        2.3 Please note that if your Personal Data has been provided to us by a third party (e.g. through a referral), you should contact that individual to make such queries, complaints, and access and correction requests to Huttons on your behalf.

        3. STATEMENT OF PRACTICES
        3.1 Types of Personal Data Collected
        As part of its day-to-day activities, Huttons may collect from you Personal Data through various means, including:
        
        when you leave your contact details with us after attending our marketing events such as roadshows and seminars;
        when you visit our show flats or open houses and leave behind your contact details or express an interest to preview a unit;
        when you wish to book a unit at our new property launch;
        when you interact with our associates or agents, e.g. via telephone calls, letters, face-to-face meetings, or email;
        when you respond to some of our marketing collaterals e.g. advertisements, brochures, flyers;
        when you request that we contact you, be included in an email or other mailing list;
        when you are contacted by, and respond to, our marketing representatives and agents;
        when you engage our associates or agents to help source for or transact the sale or purchase of a property (private or HDB);
        when you engage our associates or agents to look for and transact a rental property (private or HDB);
        when we receive references from business partners and third parties, for example, where you have been referred by them; and
        when you submit your Personal Data to us for any other reasons.
        3.2 The type of Personal Data that we collect from you may include:
        
        NRIC/FIN/Passport Number
        Name
        Address
        Gender
        Race
        Date of Birth/Age
        Phone Number
        Email Address
        Qualification
        Occupation
        Household members
        Household/Personal Income
        Credit card information
        Photo
        Tax returns
        CPF information
        Employment pass/Work permit
        3.3 Note that some of the Personal Data we collect is required by the HDB or other government agencies to check your eligibility to purchase an HDB flat or executive condo.
        3.4 If you provide us with any Personal Data relating to a third party (e.g. information of your spouse, children, or parents), by submitting such information to us, you represent to us that you have obtained the consent of the third party to you providing us with their Personal Data for the respective purposes.

        4. PURPOSE OF COLLECTION OF PERSONAL DATA
        4.1 The Personal Data mentioned in Paragraph 3.2 above is collected for the purposes of:
        
        Keeping clients informed of new property launches or existing property for sale or rent
        Following up on the client’s expression of interest to purchase or rent a property;
        Checking with HDB or government agencies on the client’s eligibility to purchase a new HDB flat or executive condo;
        Servicing a client’s application to book a unit at a new property launch;
        Processing and completing a sale/resale or rental transaction;
        when you respond to some of our marketing collaterals e.g. advertisements, brochures, flyers;
        Performing valuation of the property (using anonymized data); and
        Conducting market research and analysis (using anonymized data).
        4.2 In addition, Huttons collects your Personal Data if you submit an application to us as a candidate for employment or for an associate’s or agent’s position:
        
        Processing your application including pre-recruitment checks, such as in relation to your qualifications and work experience;
        Providing or obtaining employee references and for background screening/vetting;
        Collecting information about your suitability for the position applied for;
        Seeking CEA’s approval (for associates or agents);
        Communicating with you as required by Huttons to comply with its policies and processes, including for business continuity purposes; or
        Any other purposes relating to the aforesaid.

        5. DISCLOSURE OF PERSONAL DATA
        5.1 Without derogating from any of the above, we may also disclose your Personal Data to the following third parties:
        
        Partners
        Developers
        Co-brokers
        Insurers (for group insurance)
        Regulators and law enforcement officials
        Government agencies (HDB, CPF)
        Industry Associations (CEA)
        Lawyers
        Auditors
        Third-party service providers and consultants
        Credit, debit, and charge card companies, banks, and other entities processing payment
        Any agent acting on Huttons’ behalf for the provision of Huttons’ services.
        5.2 Huttons may also disclose your Personal Data to the above-mentioned parties in the occurrence of any of the following events:
        
        To the extent that we are required to do so by law;
        In connection with any legal proceedings or prospective legal proceedings
        To establish, exercise or defend our legal rights;
        To third parties who provide services to us or on our behalf;
        With your consent; or
        For the purposes of disaster recovery.
        5.3 Under certain circumstances, telephone calls made to us on our service hotlines and/or inquiry telephone numbers are recorded for the purposes of quality control, appraisal, as well as staff management and development. In such an event, by agreeing to this Policy, you hereby give your clear and unambiguous consent for the collection, use, and disclosure of such Personal Data in accordance with this Policy.

        6. TRANSFER OF PERSONAL DATA OVERSEAS
        6.1 Your Personal Data may be processed by Huttons, its partners, associates, agents, and third parties providing services to Huttons, in jurisdictions outside of Singapore. In this event, Huttons will comply with the data protection provisions of the Personal Data Protection Act in respect of the transferred personal data while such personal data remains in its possession or under its control; and will ensure that the recipient of your personal data is bound by legally enforceable obligations to provide your personal data a standard of protection that is comparable to that under the PDPA.

        7. ACCURACY OF PERSONAL DATA
        7.1 Where you:
        
        legitimately request access to Personal Data relating to you which is in the possession and control of Huttons;
        have any questions or feedback relating to your Personal Data or this Policy; or
        wish to correct Personal Data relating to you which is in the possession and control of Huttons, you may contact our Data Protection Officer (“DPO”) in accordance with our standard procedures as stated in this Policy. We shall accede to your request within a reasonable time and manner.
        Huttons shall not be liable for any damage, claim, and/or harm suffered by you as a result of your failure to update us of any change in your Personal Data.
        Should you fail to inform us of your new home address, any correspondence sent by us to your last home address shall be deemed to have been duly received by you.

        8. PROTECTION OF PERSONAL DATA
        8.1 Huttons uses commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security of your Personal Data and will not knowingly allow access to this data to anyone outside the Organisation, other than to you or as described in this Policy. However, we cannot ensure or warrant the security of any information you transmit to us and you do so entirely at your own risk. In particular, Huttons does not warrant that such information may not be accessed, altered, collected, copied, destroyed, disposed of, disclosed, or modified by breach of any of Huttons’ physical, technical, or managerial safeguards.

        9. ACCESS AND CORRECTION OF PERSONAL DATA
        9.1 In accordance with Paragraph 2.1 of this Policy, you have the right to:
        
        check whether Huttons holds any Personal Data relating to you and, if so, obtain copies of such data and information about the ways in which your Personal Data have been or may have been used or disclosed by Huttons within the past year; and
        require Huttons to correct any Personal Data relating to you which is inaccurate for the purpose for which it is being used.
        9.2 Huttons reserves the right to charge a reasonable administrative fee in order to meet your requests under Paragraph 7.1.1. Upon payment of the requisite fee, your request shall be processed within a reasonable time.
        9.3 If you wish to verify the details you have submitted to Huttons or if you wish to check on the manner in which Huttons uses and processes your Personal Data, our security procedures mean that we may request proof of identity before we reveal information. This proof of identity will take the form of full details of name and NRIC/FIN/Passport number. You must therefore keep this information safe as you will be responsible for any action which Huttons takes in response to a request from someone using your personal details.
        9.4 Huttons shall respond to an access or correction request within 30 days from the time the request is made. If Huttons is unable to respond within 30 days, Huttons will inform you in writing within that time frame, of the time by which it will be able to respond to the request.

        10. STORAGE AND RETENTION OF PERSONAL DATA
        10.1 Huttons will delete, as reasonably possible, or otherwise anonymize any Personal Data in the event that the Personal Data is not required for any reasonable business or legal purposes of the Organisation. The deletion or destruction of Personal Data from Huttons’ electronic, manual, or other filing systems will be done in accordance with our internal procedures and other agreements.

        11. CONTACTING YOU
        11.1 To the extent that any of the communication means which you have provided us with (which may include your telephone number and fax number) is/will be listed on the Do Not Call Registry (the “DNC”), by agreeing to this Policy, by any means of indication, you hereby grant Huttons your clear and unambiguous consent to contact you using all of your communication means you have provided to us including using voice calls, SMS, Whatsapp, MMS, fax or other similar communications applications or methods, for the purposes as stated in Paragraphs 3.4 and 3.5.

        12. CHANGE OF POLICY
        12.1 Huttons reserves the right to alter any of the clauses contained in this Policy in compliance with local legislation and/or to meet international policy requirements, and for any other purpose deemed reasonably necessary by us. You should look at these terms regularly. If you do not agree to the modified terms, you should inform us as soon as possible of the terms to which you do not consent. Pending such notice, if there is any inconsistency between these terms and the additional terms, the additional terms will prevail to the extent of the inconsistency.

        13. GOVERNING LAW
        13.1 This Policy is governed by and shall be construed in accordance with the laws of Singapore. You hereby submit to the non-exclusive jurisdiction of the Singapore courts.

        14. MISCELLANEOUS
        14.1 This Policy only applies to the collection and use of Personal Data by Huttons. It does not cover third-party sites to which we provide links, even if such sites are co-branded with our logo. Huttons does not share your Personal Data with third-party websites. Huttons is not responsible for the privacy and conduct practices of these third-party websites, so you should read their own privacy policies before disclosure of any Personal Data to these websites.
    </Typography>
    <Button
        variant="contained"
        component={Link}
        to="/"
        className={classes.backButton}
    >
        Back to Home
    </Button>
    </Container>
);
};

export default Privacy;
