import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Container } from "@material-ui/core";

const Disclaimer = () => {
return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
    <Typography variant="h4" gutterBottom>
        Disclaimer Policy
    </Typography>
    <Typography variant="body1" gutterBottom>
    The information provided in this advertisement is for general informational purposes only and does not constitute an offer or solicitation to purchase any property. The details, including prices, floorplans, and property-related information, are subject to change without prior notice. This advertisement is prepared by us and is intended solely for informational purposes. The content is provided by our property agents and is based on available information at the time of posting. While we make efforts to ensure accuracy, we do not guarantee the completeness or reliability of the information presented. We recommend contacting us directly for a more accurate and up-to-date information regarding the property. Our property agents will b e able to provide you with the most current details and answer any specific questions you may have. We shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use of or reliance on the information provided in this advertisement. Any reliance on the information is a t your own risk. Please note that this advertisement does not constitute a binding contract or agreement between t h e developer and any prospective buyer. Any transaction or agreement regarding the purchase of a property will be subject to the terms and conditions set forth by the developer or relevant authorities. This disclaimer is governed by and construed in accordance with the laws of Singapore. Any disputes or claims arising out of or in connection with this advertisement shall be subject to the exclusive jurisdiction of the courts of Singapore. Please be aware that by viewing or interacting with this advertisement, you acknowledge that you have read and understood this disclaimer.

Terms of Use


The term 'HUTTONS ASIA PTE. LTD' or 'us' or 'we' refers to the owner of the website whose registered office is 3 Bishan Pl, #05-01 CPF Bishan Building, Singapore 579838. Our company registration number is L3008899K. The term 'you' refers to the user or viewer of our website.

 

The use of this website is subject to the following terms of use:
The content of the pages of this website is for your general information and use only. It is subject to change without notice.
This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the following personal information may be stored by us for use by third parties.
Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website. Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.
From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
Your use of this website and any dispute arising out of such use of the website is subject to the laws of Singapore.
    </Typography>
    <Button
        variant="contained"
        component={Link}
        to="/"
        style={{
            background: "#00A36C",
            fontWeight: "bold",
            marginTop: "16px",
            color: "#FFFFFF",
            }}
    >
        Back to Home
    </Button>
    </Container>
);
};

export default Disclaimer;
