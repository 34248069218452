import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { Link as Rlink } from "react-router-dom";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
// import brochurePDF from "./IWS_ENG_BROCHURE.pdf";

const pages = [
{ text: "ABOUT US", href: "#about" },
// { text: "PRODUCTS", href: "#products", brochure: brochurePDF },
{ text: "FLOOR PLANS", href: "#floorplans" },
{ text: "CONTACT US", href: "#contact" },
];

const NavBar = ({ handleNavClick }) => {
const location = useLocation();
const currentPath = location.pathname;

const [anchorElNav, setAnchorElNav] = React.useState(null);
// const [anchorElUser, setAnchorElUser] = React.useState(null);

const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
};
// const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
// };

const handleCloseNavMenu = () => {
    setAnchorElNav(null);
};

// const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
// };

return (
    <AppBar
    position="static"
    sx={{
        background: "transparent",
        mt: 0.5,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    }}
    >
    <Container
        maxWidth="xl"
        sx={{
        margin: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        }}
    >
        <Toolbar disableGutters sx={{ padding: "0 16px" }}>
        <Rlink to={"/"}>
            <Box
            component="img"
            sx={{
                height: 32,
                width: 110,
                display: { xs: "none", md: "flex" },
                mr: 1,
            }}
            alt="MYST"
            src="../themystlogo.jpeg"
            />
        </Rlink>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            >
            <MenuIcon sx={{ color: "black" }} />
            </IconButton>
            <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
                display: { xs: "flex", md: "none" },
            }}
            >
            {pages.map((page, i) => (
                <MenuItem
                key={i}
                onClick={handleCloseNavMenu}
                style={{ background: "black" }}
                >
                <Rlink
                    onClick={(e) => {
                    if (currentPath === "/") {
                        handleNavClick(e.target.href);
                    }
                    }}
                    to={currentPath === "/" ? page.href : `/${page.href}`}
                    style={{
                    color: "white",
                    display: "flex",
                    textDecoration: "none",
                    fontWeight: 500,
                    }}
                >
                    {page.text}
                </Rlink>
                </MenuItem>
            ))}
            </Menu>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, i) => (
            <Rlink
                key={i}
                onClick={(e) => {
                if (currentPath === "/") {
                    handleNavClick(e.target.href);
                }
                }}
                to={currentPath === "/" ? page.href : `/${page.href}`}
                target={page?.target}
                style={{
                margin: "16px",
                color: "black",
                display: "flex",
                textDecoration: "none",
                fontWeight: 500,
                }}
            >
                {page.text}
            </Rlink>
            ))}
        </Box>
        </Toolbar>
    </Container>
    </AppBar>
);
};

export default NavBar;
