import { Box, Grid, IconButton, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: '#EEEFF1',
        padding: theme.spacing(1, 0),
        marginTop: theme.spacing(4),
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    logoText: {
        fontWeight: 'bold',
        marginLeft: theme.spacing(2),
    },
    icon: {
        color: '#000000',
        marginLeft: theme.spacing(2),
    },
    copyright: {
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(2),
        textAlign: 'center',
    },
    centerText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',  // Ensure the Box takes full width
    },
}));

function Footer() {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                    <Box className={classes.centerText}>
                        <Typography variant="caption" style={{ color: '#000000' }}>
                            The Myst - © Copyright 2024. All Rights Reserved.
                            <Link href="/disclaimer" style={{ marginLeft: 16, marginRight: 16, color: "#000000" }}>
                                Disclaimer
                            </Link>
                            <Link href="/privacy" style={{ marginRight: 16, color: "#000000" }}>
                                Privacy Policy
                            </Link>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box className={classes.copyright}>
                <Typography variant="caption" style={{ color: '#000000' }}>
                    +6598477000 | Huttons Asia Pte Ltd | L3008899K | R066500G
                </Typography>
            </Box>
        </footer>
    );
}

export default Footer;
